import React, { Suspense } from "react";
import FullPageLoader from "./components/FullPageLoader";
import Providers from "./modules/Providers";
import AppRouter from "./modules/Router";

export default function App() {
  return (
    <Suspense fallback={<FullPageLoader />}>
      <Providers>
        <AppRouter />
      </Providers>
    </Suspense>
  );
}
