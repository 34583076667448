import { parse } from "csv-parse/browser/esm";
import { supportedLanguages } from "utils";

const reservedFields = [
  "id",
  "hasAnswered",
  "opt_in",
  "firstname_normalised",
  "lastname_normalised",
];

export const csvReader = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const guests = reader.result;
      parse(
        guests.trim(),
        {
          delimiter: ";",
          columns: true,
          cast: (value, context) => {
            if (reservedFields.includes(context.column)) {
              return undefined;
            }

            if (context.column === "tags") {
              if (value === "") {
                return undefined;
              }

              return value.split(",").map((tag) => tag.trim());
            }

            if (context.column === "plusOne") {
              return +value || 0;
            }

            if (context.column === "group") {
              if (value === "") {
                return undefined;
              }

              return value;
            }

            if (context.column === "language") {
              if (supportedLanguages.includes(value)) {
                return value;
              }

              return undefined;
            }

            return value;
          },
        },
        (err, guestsList) => {
          if (err) {
            reject(err);
          }

          resolve(guestsList);
        },
      );
    };

    reader.readAsText(file);
  });

export const getFileExtension = (type) => {
  switch (type) {
    case "image/gif":
      return ".gif";
    case "image/jpeg":
    case "image/jpg":
      return ".jpeg";
    case "image/png":
      return ".png";
    case "video/mp4":
      return ".mp4";
    default:
      return "";
  }
};
