import FullPageLoader from "components/FullPageLoader";
import { initializeFirebase } from "modules/firebase";
import { useEffect, useState } from "react";

const Firebase = ({ children }) => {
  const [appCheckReady, setAppCheckReady] = useState(false);

  useEffect(() => {
    initializeFirebase().then(() => {
      setAppCheckReady(true);
    });
  }, []);

  if (!appCheckReady) {
    return <FullPageLoader />;
  }

  return children;
};

export default Firebase;
