import {
  addDoc,
  collection,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { firestore } from "modules/firebase";
import { prepareWhatsappNumber } from "utils/whatsapp";

const listEmails = async ({ projectId, campaignId, sendId }) => {
  const mailCollection = collection(firestore, "mail");
  const messagesCollection = collection(firestore, "messages");

  const queryConstraints = [
    where("metadata.project", "==", projectId),
    where("metadata.campaign", "==", campaignId),
  ];

  if (sendId) {
    queryConstraints.push(where("metadata.send", "==", sendId));
  }

  const queryMailRef = query(mailCollection, ...queryConstraints);

  const queryMessagesRef = query(messagesCollection, ...queryConstraints);

  const emailsSnapshot = await getDocs(queryMailRef);
  const messagesSnapshot = await getDocs(queryMessagesRef);

  const emails = emailsSnapshot.docs.map((email) => ({
    id: email.id,
    ...email.data(),
  }));

  const messages = messagesSnapshot.docs.map((message) => ({
    id: message.id,
    ...message.data(),
  }));

  return [...emails, ...messages];
};

const listMessagesByGuest = ({ projectId, guestId, handler }) => {
  const messagesCollection = collection(firestore, "messages");

  const queryMessagesRef = query(
    messagesCollection,
    where("metadata.project", "==", projectId),
    where("metadata.guest", "==", guestId),
    orderBy("created_at", "desc"),
  );

  return onSnapshot(queryMessagesRef, (messagesSnapshot) => {
    const messages = messagesSnapshot.docs.map((message) => ({
      id: message.id,
      ...message.data(),
    }));

    handler(messages);
  });
};

const sendMessage = async ({ projectId, guest, message }) => {
  const messagesCollection = collection(firestore, "messages");

  const document = {
    created_at: serverTimestamp(),
    updated_at: serverTimestamp(),
    message: {
      to: prepareWhatsappNumber(guest.whatsapp),
      type: "text",
      text: {
        preview_url: true,
        body: message,
      },
    },
    metadata: {
      project: projectId,
      guest: guest.id,
      campaign: "individual",
      send: "individual",
    },
    sent_at: null,
    response: null,
    open: false,
    click: false,
    status: "SCHEDULED",
  };

  const messageRef = await addDoc(messagesCollection, document);

  const newMessage = await getDoc(messageRef);

  return {
    id: newMessage.id,
    ...newMessage.data(),
  };
};

const api = {
  list: listEmails,
  listByGuest: listMessagesByGuest,
  send: sendMessage,
};

export default api;
