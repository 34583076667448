import { Center } from "@chakra-ui/react";
import { Loader } from "../Loader";

const FullPageLoader = () => {
  return (
    <Center h="$100vh">
      <Loader />
    </Center>
  );
};

export default FullPageLoader;
