import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { firestore } from "modules/firebase";
import { prepareWhatsappNumber } from "utils/whatsapp";

const listChats = async ({ projectId }) => {
  const chatsCollection = collection(firestore, "whatsapp_chats");

  const queryRef = query(
    chatsCollection,
    where("metadata.project", "array-contains", projectId),
    where("read", "==", false),
  );

  const querySnapshot = await getDocs(queryRef);

  return querySnapshot.docs.map((chat) => ({
    id: chat.id,
    ...chat.data(),
  }));
};

const updateChat = async ({ chatId, fields }) => {
  const chatRef = doc(firestore, "whatsapp_chats", chatId);

  await updateDoc(chatRef, { ...fields, updated_at: serverTimestamp() });
};

const listMessagesByGuest = ({ projectId, whatsapp, handler }) => {
  const messagesCollection = collection(firestore, "whatsapp_chats");

  const queryMessagesRef = query(
    messagesCollection,
    where("metadata.project", "array-contains", projectId),
    where("contact.wa_id", "==", prepareWhatsappNumber(whatsapp)),
    orderBy("timestamp", "desc"),
  );

  return onSnapshot(queryMessagesRef, (messagesSnapshot) => {
    const messages = messagesSnapshot.docs.map((message) => ({
      id: message.id,
      ...message.data(),
    }));

    handler(messages);
  });
};

const api = {
  list: listChats,
  update: updateChat,
  listByGuest: listMessagesByGuest,
};

export default api;
