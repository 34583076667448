import { AuthContext } from "modules/Auth";
import { useContext } from "react";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({ children, ...rest }) => {
  const { isAuthenticated, isLoading } = useContext(AuthContext);

  return (
    !isLoading && (
      <Route
        {...rest}
        render={({ location }) => {
          if (isAuthenticated) {
            return children;
          }

          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { redirect: location },
              }}
            />
          );
        }}
      />
    )
  );
};

export default PrivateRoute;
