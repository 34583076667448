import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  getToken,
  initializeAppCheck,
  ReCaptchaV3Provider,
} from "firebase/app-check";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import { getPerformance } from "firebase/performance";
import {
  connectStorageEmulator,
  getDownloadURL,
  getMetadata,
  getStorage,
  ref,
} from "firebase/storage";

export {
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
getPerformance(firebaseApp);
const analytics = getAnalytics(firebaseApp);

export const auth = getAuth(firebaseApp);
export const firestore = getFirestore(firebaseApp);
export const functions = getFunctions(firebaseApp, "europe-west1");
export const storage = getStorage(firebaseApp);

export const initializeFirebase = async () => {
  if (process.env.NODE_ENV === "development") {
    global.FIREBASE_APPCHECK_DEBUG_TOKEN =
      process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN || true;

    connectAuthEmulator(auth, "http://localhost:9099");
    connectFirestoreEmulator(firestore, "localhost", 8080);
    connectFunctionsEmulator(functions, "localhost", 5001);
    connectStorageEmulator(storage, "localhost", 9199);
  }

  const appCheck = initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(
      "6Leb-VcdAAAAAOlj0mj7wt9P2ZzRb-PH5tYIa9KA",
    ),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true,
  });

  // Wait for the token to avoid invalid request for RSVP page
  return await getToken(appCheck);
};

const addGuestFunction = httpsCallable(functions, "addGuest");
const getMembersDetailsFunction = httpsCallable(functions, "getMembersDetails");
const inviteMemberFunction = httpsCallable(functions, "inviteMember");
const joinProjectFunction = httpsCallable(functions, "joinProject");
const rsvpFunction = httpsCallable(functions, "rsvp");
const sendTestMessageFunction = httpsCallable(functions, "sendTestMessage");
const validateSlugFunction = httpsCallable(functions, "validateSlug");

export const functionsEndpoints = {
  addGuest: addGuestFunction,
  answerSurvey: (data) =>
    rsvpFunction({
      type: "answerSurvey",
      data,
    }),
  getMembersDetails: getMembersDetailsFunction,
  inviteMember: inviteMemberFunction,
  joinProject: joinProjectFunction,
  retrieveGuestAnswers: (data) =>
    rsvpFunction({
      type: "retrieveGuestAnswers",
      data,
    }),
  retrieveGuestDetails: (data) =>
    rsvpFunction({
      type: "retrieveGuestDetails",
      data,
    }),
  retrieveProjectDetails: (data) =>
    rsvpFunction({
      type: "retrieveProjectDetails",
      data,
    }),
  retrieveSurvey: (data) =>
    rsvpFunction({
      type: "retrieveSurvey",
      data,
    }),
  sendTestMessage: sendTestMessageFunction,
  validateSlug: validateSlugFunction,
};

export const logAnalytics = (eventName, eventParams) => {
  logEvent(analytics, eventName, eventParams);
};

export const getAttachmentDetails = async (path) => {
  const fileRef = ref(storage, path);
  const url = await getDownloadURL(fileRef);
  const metadata = await getMetadata(fileRef);

  return {
    url,
    metadata,
  };
};

export default firebaseApp;
