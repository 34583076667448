import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import Auth from "modules/Auth";
import store from "modules/Store";
import { Provider as ReduxProvider } from "react-redux";
import Firebase from "./Firebase";
import { chakraTheme } from "./theme";

const Providers = ({ children }) => (
  <ChakraProvider theme={chakraTheme} portalZIndex={11}>
    <ColorModeScript initialColorMode={chakraTheme.config.initialColorMode} />

    <Firebase>
      <ReduxProvider store={store}>
        <Auth>{children}</Auth>
      </ReduxProvider>
    </Firebase>
  </ChakraProvider>
);

export default Providers;
